import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { PageHeading, PageParagraph, PageSubHeading, SimplePage } from "../components/SimplePage"
import SimplePageHeader from "../components/SimplePageHeader"

const Imprint = () => (
  <Layout>
    <SEO title="Imprint" />
    <SimplePage>
      <SimplePageHeader title="Imprint" subtitle="Updated Mar 29. 2019" />

      <PageHeading>Legal Disclosure</PageHeading>
      <PageParagraph>Information in accordance with section 5 TMG</PageParagraph>
      <PageParagraph>
        Lehmann Ventures UG (haftungsbeschränkt)
        <br />
        Holdermannstr. 42B
        <br />
        70567 Stuttgart
      </PageParagraph>
      <PageParagraph>Geschäftsführung: Erich Lehmann</PageParagraph>
      <PageParagraph>
        Registereintrag: Eintragung im Handelsregister
        <br />
        Registergericht: Stuttgart
        <br />
        Registernummer: 758155
      </PageParagraph>

      <PageHeading>Contact</PageHeading>
      <PageParagraph>
        Email: erich@lumis.ai
        <br />
        Phone: +49 176 209 566 86
        <br />
        Website: www.lumis.ai
      </PageParagraph>

      <PageHeading>Disclaimer</PageHeading>
      <PageSubHeading>Accountability for content</PageSubHeading>
      <PageParagraph>
        The contents of our pages have been created with the utmost care. However, we cannot
        guarantee the contents’ accuracy, completeness or topicality. According to statutory
        provisions, we are furthermore responsible for our own content on these web pages. In this
        context, please note that we are accordingly not obliged to monitor merely the transmitted
        or saved information of third parties, or investigate circumstances pointing to illegal
        activity. Our obligations to remove or block the use of information under generally
        applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).
      </PageParagraph>

      <PageSubHeading>Accountability for links</PageSubHeading>
      <PageParagraph>
        Responsibility for the content of external links (to web pages of third parties) lies solely
        with the operators of the linked pages. No violations were evident to us at the time of
        linking. Should any legal infringement become known to us, we will remove the respective
        link immediately.
      </PageParagraph>

      <PageSubHeading>Copyright</PageSubHeading>
      <PageParagraph>
        Our web pages and their contents are subject to German copyright law. Unless expressly
        permitted by law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing
        or processing works subject to copyright protection on our web pages requires the prior
        consent of the respective owner of the rights. Individual reproductions of a work are
        allowed only for private use, so must not serve either directly or indirectly for earnings.
        Unauthorized utilization of copyrighted works is punishable (§ 106 of the copyright law).
      </PageParagraph>
    </SimplePage>
  </Layout>
)

export default Imprint
